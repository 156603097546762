const compareChartTabs = [
  {
    key: 1,
    label: "Views",
    dataGraph: "views",
    dataColor: "#9D63B0",
    dataHide: "",
    keyName: "count",
  },
  {
    key: 2,
    label: "Subscribers",
    dataGraph: "subs",
    dataColor: "#7375db",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 3,
    label: "VPV90",
    dataGraph: "vpv90",
    dataColor: "#6edfd1",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 4,
    label: "Shorts VPV90",
    dataGraph: "shvpv90",
    dataColor: "#00d114",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 5,
    label: "Longs VPV90",
    dataGraph: "lgvpv90",
    dataColor: "#ff9415",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 6,
    label: "VPV30",
    dataGraph: "vpv30",
    dataColor: "#6edf83",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 7,
    label: "V30Day",
    dataGraph: "v30day",
    dataColor: "#f5bd5a",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 8,
    label: "V7Day",
    dataGraph: "v7day",
    dataColor: "#f55a5a",
    dataHide: "hourly",
    keyName: "count",
  },
  {
    key: 9,
    label: "CLPMV30",
    dataGraph: "cldpmv30",
    dataColor: "#516eff",
    dataHide: "hourly",
    keyName: "count",
  },
];

const colorList = [
  "#9D63B0",
  "#7375db",
  "#6edfd1",
  "#00d114",
  "#ff9415",
  "#6edf83",
  "#f5bd5a",
  "#f55a5a",
  "#516eff",
];


const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colorList.length);
    return colorList[randomIndex];
  };

export { compareChartTabs, colorList, getRandomColor };
