import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slices/userSlice";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import GospelButton from "../../common/GospelButton/GospelButton";
import GospelInput from "../../common/GospelInput/GospelInput";
import Footer from "../../common/Footer/Footer";
import Header from "../../common/Header/Header";
import { get, post } from "../../utility/api";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { User } from "../../types/account";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setError("Please enter valid email");
      return;
    }
    setError("");

    try {
      const data = new URLSearchParams();
      data.append("email", email);
      data.append("pass", password);
      setLoading(true);
      const response: User = await post("/gospel/user/authenticate", data);
      if (response) {
        dispatch(setUser({ user: response }));
        
        setLoading(false);
        navigate("/dashboard");
      } else {
        setError("Login Failed");
        setLoading(false);
      }
    } catch (error) {
      setError("Login Failed");
      setLoading(false);
    }
  };

  return (
    <>
      <Header isTabBarVisible={false} />
      <div
        className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage:
            'url("https://www.gospelstats.com/wp-content/uploads/2018/05/home_image4.jpg")',
        }}
      >
        <div className="w-[calc(100%-40px)] max-w-[350px] text-center z-2">
          <div className="absolute inset-0 bg-black opacity-65"></div>
          <div className="relative z-10 w-full max-w-md text-center bg-white p-6 rounded-lg shadow-lg">
            <img
              src="https://www.gospelstats.com/wp-content/uploads/2018/05/icon_large.png"
              alt="Logo"
              className="w-24 mx-auto -mt-16 mb-4"
            />
            <GospelInput
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="block w-full mt-6 max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
            <GospelInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
              className="block my-4 w-full max-w-[450px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />

            {error && (
              <div className="text-gospel-error-red mb-6 text-sm bg-gospel-red-100 px-3 py-2 rounded">
                {error}
              </div>
            )}

            <GospelButton
              className="self-center text-white h-[40px] w-full p-2 rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple"
              label={
                loading ? (
                  <CircularProgress
                    className="self-center justify-center text-gospel-gray-100 font-bold"
                    size={16}
                  />
                ) : (
                  "LOGIN"
                )
              }
              onClick={handleLogin}
              disabled={!email || !password}
            />
          </div>
          <p className="mt-6">
            <a
              href="/password"
              className="hover:underline text-white transition-opacity duration-500 ease opacity-65 text-sm"
            >
              Forgot password?
            </a>
            <span className="mx-2 text-white transition-opacity duration-500 ease opacity-65 text-sm">
              |
            </span>
            <a
              href="/"
              className="hover:underline text-white transition-opacity duration-500 ease opacity-65 text-sm"
            >
              Request an invite
            </a>
            <br />
            <a
              href="mailto:contact@gospelstats.com"
              className="hover:underline mt-2 block text-white transition-opacity duration-500 ease opacity-65 text-sm text-center"
            >
              contact@gospelstats.com
            </a>
          </p>
          <div className="mt-4 flex justify-center space-x-4">
            <a
              href="https://www.twitter.com/GospelStats/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white transition-opacity duration-500 ease opacity-65  hover:opacity-100"
            >
              <XIcon className="text-xl" />
            </a>
            <a
              href="https://www.instagram.com/GospelStats/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white transition-opacity duration-500 ease opacity-65 hover:opacity-100"
            >
              <InstagramIcon className="text-xl" />
            </a>
            <a
              href="https://www.facebook.com/GospelStats/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white transition-opacity duration-500 ease opacity-65 hover:opacity-100"
            >
              <FacebookIcon className="text-xl" />
            </a>
          </div>
        </div>
      </div>
      <Footer isPolicyVisible={false} />
    </>
  );
};

export default Login;
