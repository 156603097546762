import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import {
  ConfigProvider,
  Collapse,
  CollapseProps,
  DatePicker,
  DatePickerProps,
  Checkbox,
  Tooltip,
  Avatar,
  Modal,
} from "antd";
import {
  Cancel,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  RemoveRedEyeSharp,
} from "@mui/icons-material";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import { categoryOptions, dataOptions, shortOptions } from "../../types/charts";
import { get, post } from "../../utility/api";
import {
  Country,
  Language,
  Option,
  formatNumberWithCommas,
  removeCommasAndConvertToInt,
} from "../../utility/common";
import GospelInput from "../../common/GospelInput/GospelInput";
import GospelButton from "../../common/GospelButton/GospelButton";
import { CircularProgress } from "@mui/material";
import { DiscoverStats, Watchlist, sortOptions } from "./DiscoverUtils";
import { useNavigate } from "react-router-dom";
import { orderByOptions } from "../Brand/BrandUtils";
import { WatchlistItem } from "../../types/watchlist";

interface DiscoverFormData {
  channelName: string;
  categories: string;
  language: string;
  country: string;
  keyword: string;
  keywordslocation: string;
  madeforkids: string;
  minSubscribers: number;
  maxSubscribers: number;
  minViews: number;
  maxViews: number;
  minSubs30Days: number;
  maxSubs30Days: number;
  minVPV30Shorts: number;
  maxVPV30Shorts: number;
  minVPV30Longs: number;
  maxVPV30Longs: number;
  minView30Days: number;
  maxView30Days: number;
  maxShortUploads30Days: number;
  minShortUploads30Days: number;
  maxLongUploads30Days: number;
  minLongUploads30Days: number;
  creationDate?: number | null;
  creationTimeframe: string;
  keywordOptions: string[];
}

export const creationDateOptions = [
  { value: "before", label: "Before" },
  { value: "after", label: "After" },
];

function Discover() {
  const [activeKey, setActiveKey] = useState<string | string[]>("1");
  const [languageList, setLanguageList] = useState<Option[]>([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [keywordOptions, setKeywordOptions] = useState<string[]>([]);
  const [loadDiscoverData, setLoadDiscoverData] = useState<boolean>(false);
  const [discoverData, setDiscoverData] = useState<DiscoverStats>(
    {} as DiscoverStats
  );
  const [channelsToBeAdded, setChannelsToBeAdded] = useState<string>("");
  const [addWatchlistloading, setAddWatchlistLoading] =
    useState<boolean>(false);
  const [newWatchlistName, setNewWatchlistName] = useState<string>("");
  const [watchlistNameError, setWatchlistNameError] = useState<string>("");
  const [watchlistModalOpen, setWatchlistModalOpen] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>("allTimeSubs");
  const [selectedOrderBy, setSelectedOrderBy] = useState<string>("desc");
  // Pagination State
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const limit = 50; // Number of items per page
  const [watchList, setWatchList] = useState<WatchlistItem[]>([]);
  const [watchlistListModalOpen, setWatchlistListModalOpen] =
    useState<boolean>(false);
  const [channelWatchlist, setChannelWatchlist] = useState<Watchlist[]>([]);

  const [discoverFormData, setDiscoverFormData] = useState<DiscoverFormData>({
    channelName: "",
    categories: "all",
    language: "",
    country: "",
    keyword: "",
    keywordslocation: "",
    madeforkids: "2",
    minSubscribers: 0,
    maxSubscribers: 0,
    minViews: 0,
    maxViews: 0,
    minSubs30Days: 0,
    maxSubs30Days: 0,
    minVPV30Shorts: 0,
    maxVPV30Shorts: 0,
    minVPV30Longs: 0,
    maxVPV30Longs: 0,
    minView30Days: 0,
    maxView30Days: 0,
    maxShortUploads30Days: 0,
    minShortUploads30Days: 0,
    maxLongUploads30Days: 0,
    minLongUploads30Days: 0,
    creationDate: null,
    creationTimeframe: "after",
    keywordOptions: [],
  });
  const navigate = useNavigate();

  const handleCheckboxChange = (checkedValues: string[]) => {
    setKeywordOptions(checkedValues);
  };

  const handleInputChange = (field: keyof DiscoverFormData, value: string) => {
    const formattedValue = removeCommasAndConvertToInt(value);
    setDiscoverFormData((prev) => ({
      ...prev,
      [field]: formattedValue,
    }));
  };

  const addToWatchlist = async (watchlistId: number, channelId: string) => {
    try {
      setAddWatchlistLoading(true);
      const data = new URLSearchParams();
      data.append("list_id", watchlistId.toString());
      data.append("channel_id", channelId);

      const response = await post("/gospel/watchlist/add", data);
      if (response) {
        setAddWatchlistLoading(false);
      }
      setWatchlistModalOpen(false);
      fetchWatchlist();
    } catch (error) {
      console.error("Error adding ̦to watchlist:", error);
      setAddWatchlistLoading(false);
    }
  };

  const createNewWatchlist = async () => {
    try {
      setLoading(true);
      if (newWatchlistName.trim() === "") {
        setWatchlistNameError("Please enter a watchlist name.");
        return;
      }
      await get(`/gospel/watchlist/create?title=${newWatchlistName}`);
      fetchWatchlist();
      setNewWatchlistName("");
      setWatchlistNameError("");
      setLoading(false);
    } catch (error) {
      console.error("Error creating new watchlist:", error);
      setLoading(false);
    }
  };

  const fetchWatchlist = async () => {
    try {
      const result: any = await get("/gospel/watchlist/list");
      if (result) {
        setWatchList(result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onCreationDateChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    if (date) {
      setDiscoverFormData((prev) => ({
        ...prev,
        creationDate: date.valueOf(),
      }));
    } else {
      setDiscoverFormData((prev) => ({
        ...prev,
        creationDate: null,
      }));
    }
  };

  const onDiscoverData = async (
    newOffset: number,
    sort: string = selectedSort,
    order: string = selectedOrderBy
  ) => {
    setLoading(true);
    setLoadDiscoverData(true);
    setActiveKey([]);
    const params = new URLSearchParams({
      categories: discoverFormData.categories || "all", // Use "all" if categories is not defined
      channel: discoverFormData.channelName,
      country: discoverFormData.country || "", // Default to an empty string if not provided
      creationdate: discoverFormData.creationDate
        ? discoverFormData.creationDate.toString()
        : "",
      creationtimeframe: discoverFormData.creationTimeframe,
      keywords: discoverFormData.keyword,
      keywordslocation: discoverFormData.keywordOptions.join(",") || "", // Default to an empty string if no options
      language: discoverFormData.language || "", // Default to an empty string if not provided
      madeforkids: discoverFormData.madeforkids || "both", // Default to "both" if not provided
      maxsubs: discoverFormData.maxSubscribers
        ? discoverFormData.maxSubscribers.toString()
        : "",
      maxsubs30days: discoverFormData.maxSubs30Days
        ? discoverFormData.maxSubs30Days.toString()
        : "",
      maxuploadslongs: discoverFormData.maxLongUploads30Days
        ? discoverFormData.maxLongUploads30Days.toString()
        : "",
      maxuploadsshorts: discoverFormData.maxShortUploads30Days
        ? discoverFormData.maxShortUploads30Days.toString()
        : "",
      maxviews: discoverFormData.maxViews
        ? discoverFormData.maxViews.toString()
        : "",
      maxviews30days: discoverFormData.maxView30Days
        ? discoverFormData.maxView30Days.toString()
        : "",
      maxvpv30longs: discoverFormData.maxVPV30Longs
        ? discoverFormData.maxVPV30Longs.toString()
        : "",
      maxvpv30shorts: discoverFormData.maxVPV30Shorts
        ? discoverFormData.maxVPV30Shorts.toString()
        : "",
      minsubs: discoverFormData.minSubscribers
        ? discoverFormData.minSubscribers.toString()
        : "",
      minsubs30days: discoverFormData.minSubs30Days
        ? discoverFormData.minSubs30Days.toString()
        : "",
      minuploadslongs: discoverFormData.minLongUploads30Days
        ? discoverFormData.minLongUploads30Days.toString()
        : "",
      minuploadsshorts: discoverFormData.minShortUploads30Days
        ? discoverFormData.minShortUploads30Days.toString()
        : "",
      minviews: discoverFormData.minViews
        ? discoverFormData.minViews.toString()
        : "",
      minviews30days: discoverFormData.minView30Days
        ? discoverFormData.minView30Days.toString()
        : "",
      minvpv30longs: discoverFormData.minVPV30Longs
        ? discoverFormData.minVPV30Longs.toString()
        : "",
      minvpv30shorts: discoverFormData.minVPV30Shorts
        ? discoverFormData.minVPV30Shorts.toString()
        : "",
      limit: limit.toString(),
      offset: newOffset.toString(),

      orderby: order, // Fixed sorting order
      sortby: sort, // Fixed sorting criteria
    });
    try {
      const response = await get<DiscoverStats>(
        `/youtube/stats/discover?${params}`
      );
      if (response) {
        setDiscoverData(response);
        setOffset(newOffset);
        setCurrentPage(newOffset / limit + 1);
      }
    } catch (error) {
      console.error("Error fetching discover data:", error);
    } finally {
      setLoading(false);
      setLoadDiscoverData(false);
    }
  };

  const handleFirstPage = () => {
    if (currentPage > 1) {
      onDiscoverData(0);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newOffset = offset - limit;
      onDiscoverData(newOffset);
    }
  };

  const handleNextPage = () => {
    if (discoverData?.items?.length === limit) {
      const newOffset = offset + limit;
      onDiscoverData(newOffset);
    }
  };

  const handleSortChange = (value: string) => {
    setSelectedSort(value);
    onDiscoverData(0, value, selectedOrderBy); // Pass updated sort value
  };

  // Handle Order By Change
  const handleOrderByChange = (value: string) => {
    setSelectedOrderBy(value);
    onDiscoverData(0, selectedSort, value); // Pass updated order by value
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex flex-row mx-6 pb-6 border-b border-gospel-gray-400 justify-between items-center">
          <h1 className="text-xl font-semibold text-gospel-gray text-left ">
            Search Criteria
          </h1>
          {activeKey === "1" ? (
            <button onClick={() => handleToggle("1")}>
              <KeyboardArrowUp fontSize="large" />
            </button>
          ) : (
            <button onClick={() => handleToggle("1")}>
              <KeyboardArrowDown fontSize="large" />
            </button>
          )}
        </div>
      ),
      children: (
        <div>
          <div className="flex flex-wrap mx-2">
            {/* Left Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Channel Name:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      channelName: e.target.value,
                    }));
                  }}
                  value={discoverFormData.channelName}
                  placeholder="Enter Channel Name"
                  className="w-[480px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Categories:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelSelectBox
                  options={categoryOptions}
                  value={discoverFormData.categories}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      categories: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[480px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Country:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelSelectBox
                  options={countryList}
                  value={discoverFormData.country}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      country: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[480px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Language:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <GospelSelectBox
                  options={languageList}
                  value={discoverFormData.language}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      language: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[480px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
            </div>
            {/* Right Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Total Subscribers:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minSubscribers", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minSubscribers
                  )}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxSubscribers", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxSubscribers
                  )}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Total Views:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minViews", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minViews)}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxViews", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxViews)}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Creation Date:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelSelectBox
                  options={creationDateOptions}
                  value={discoverFormData.creationTimeframe}
                  onChange={(value) =>
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      creationTimeframe: value,
                    }))
                  }
                  containerClass="relative"
                  className="w-[242px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
                <span className="text-gray-400 mx-0.5"></span>
                <DatePicker
                  onChange={onCreationDateChange}
                  className="h-10 w-[253px]"
                />
              </div>
            </div>

            <div className="border-b border-gospel-gray-400 w-full my-8" />
            {/* Keywords Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-8">
                <GospelLabel
                  label={"Keywords:"}
                  className="text-sm text-gray-400 w-36 text-left"
                />
                <div className="flex flex-col">
                  <GospelInput
                    type="text"
                    onChange={(e) => {
                      setDiscoverFormData((prev) => ({
                        ...prev,
                        keyword: e.target.value,
                      }));
                    }}
                    value={discoverFormData.keyword}
                    placeholder="Enter Keywords"
                    className="w-[480px] h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                  />
                  <div className="flex flex-row items-start mt-4 gap-8">
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "transperant",
                          colorPrimaryHover: "transperant",
                          colorText: "transperant",
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: 12,
                        },
                      }}
                    >
                      <Checkbox.Group
                        options={["Channel Description", "Channel Keywords"]}
                        onChange={handleCheckboxChange}
                        className="font-normal text-gray-400 font-montserrat text-xs"
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
            {/* Made for Kids */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Made for Kids:"}
                  className="text-sm text-gray-400 w-44 text-left"
                />
                <GospelSelectBox
                  options={shortOptions}
                  value={discoverFormData.madeforkids}
                  onChange={(value) => {
                    setDiscoverFormData((prev) => ({
                      ...prev,
                      madeforkids: value,
                    }));
                  }}
                  containerClass="relative"
                  className="w-[515px] h-10 text-sm font-medium bg-white text-left"
                  placeholder="Select"
                />
              </div>
            </div>

            <div className="border-b border-gospel-gray-400 w-full my-8" />
            {/* Subs - 30 Days */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Subs - 30 Days:"}
                  className="text-sm text-gray-400 w-40 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minSubs30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minSubs30Days)}
                  placeholder="Min"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxSubs30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxSubs30Days)}
                  placeholder="Max"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <div className="w-40 flex items-start">
                  <GospelLabel
                    label={"VPV30 Shorts:"}
                    className="text-sm text-gray-400 text-left"
                  />
                  <Tooltip
                    placement="top"
                    title={
                      "The average views per video on all the videos uploaded to a channel in the last 30 days."
                    }
                  >
                    <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                  </Tooltip>
                </div>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minVPV30Shorts", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minVPV30Shorts
                  )}
                  placeholder="Min"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxVPV30Shorts", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxVPV30Shorts
                  )}
                  placeholder="Max"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <div className="w-40 flex items-start">
                  <GospelLabel
                    label={"VPV30 Longs:"}
                    className="text-sm text-gray-400 text-left"
                  />
                  <Tooltip
                    placement="top"
                    title={
                      "The average views per video on all the videos uploaded to a channel in the last 30 days."
                    }
                  >
                    <InfoOutlined htmlColor="#e2e3e3" className="mx-1" />
                  </Tooltip>
                </div>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minVPV30Longs", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minVPV30Longs)}
                  placeholder="Min"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxVPV30Longs", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxVPV30Longs)}
                  placeholder="Max"
                  className="h-10 w-56 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
            </div>
            {/* Right Column */}
            <div className="w-1/2">
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Views - 30 Days:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minView30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.minView30Days)}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxView30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(discoverFormData.maxView30Days)}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Shorts Uploads - 30 Days:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minShortUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minShortUploads30Days
                  )}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxShortUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxShortUploads30Days
                  )}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
              <div className="py-2 flex flex-row items-center gap-4">
                <GospelLabel
                  label={"Longs Uploads - 30 Days:"}
                  className="text-sm text-gray-400 w-48 text-left"
                />
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("minLongUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.minLongUploads30Days
                  )}
                  placeholder="Min"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                <span className="text-gray-400">-</span>
                <GospelInput
                  type="text"
                  onChange={(e) =>
                    handleInputChange("maxLongUploads30Days", e.target.value)
                  }
                  value={formatNumberWithCommas(
                    discoverFormData.maxLongUploads30Days
                  )}
                  placeholder="Max"
                  className="h-10 w-64 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
              </div>
            </div>

            <div className="border-b border-gospel-gray-400 w-full my-8 " />
          </div>
          <GospelButton
            className=" text-white h-[40px] w-[90px] rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
            label={"Search"}
            onClick={() => onDiscoverData(0)}
          />
        </div>
      ),
    },
  ];

  const handleToggle = (key: string | string[]) => {
    setActiveKey(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countryResult, languageResult] = await Promise.all([
          get<Country[]>("gospel/countries/list"),
          get<Language[]>("gospel/languages/list"),
        ]);

        const transformedCountryOptions = [
          { value: "all", label: "All" },
          ...countryResult.map((country) => ({
            value: country.code,
            label: country.name,
          })),
        ];

        const transformedLanguageOptions = [
          { value: "all", label: "All" },
          ...languageResult.map((language) => ({
            value: language.code,
            label: language.name,
          })),
        ];

        setCountryList(transformedCountryOptions);
        setLanguageList(transformedLanguageOptions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchWatchlist();
  }, []);

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-col items-center justify-center mt-[70px] md:mt-[70px]">
        <div className="text-center py-4 max-w-screen-md mx-auto overflow-y-auto mt-5">
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl mb-2 text-gospel-gray">
            Discover
          </h1>
        </div>
        <div className="text-center pb-10 w-full">
          <div className="mx-8 py-8 bg-white rounded-md shadow-md">
            <ConfigProvider
              theme={{
                components: {
                  Collapse: {
                    headerPadding: 0,
                    fontFamily: "Montserrat, sans-serif",
                  },
                },
              }}
            >
              <Collapse
                items={items}
                ghost
                expandIcon={() => null}
                defaultActiveKey={["1"]}
                activeKey={activeKey}
                onChange={handleToggle}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
      <div className="px-8 w-full my-6">
        <div className="flex flex-col py-8">
          {loadDiscoverData ? (
            <div className="p-8 flex items-center justify-center">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            discoverData &&
            discoverData?.items?.length > 0 && (
              <>
                <div className=" bg-white rounded-lg p-8 shadow-md mb-8">
                  <p>{`Total Results: ${discoverData.resultCount}`}</p>
                  <div className="flex flex-row w-full items-center justify-between my-4">
                    <div className="flex flex-row items-center">
                      <GospelLabel
                        label={"Sort By:"}
                        className="text-sm text-gray-400 w-36"
                      />
                      <GospelSelectBox
                        options={sortOptions}
                        value={selectedSort}
                        onChange={handleSortChange}
                        containerClass="relative"
                        className="w-[500px] h-10"
                        placeholder="Select"
                      />
                    </div>
                    <div className="flex flex-row items-center">
                      <GospelLabel
                        label={"Order By:"}
                        className="text-sm text-gray-400 w-36"
                      />
                      <GospelSelectBox
                        options={orderByOptions}
                        value={selectedOrderBy}
                        onChange={handleOrderByChange}
                        containerClass="relative"
                        className="w-[500px] h-10"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  {/* Table Container */}
                  <div className="min-w-full divide-y divide-gray-200">
                    {/* Table Head */}
                    <div className="flex text-base text-gospel-gray-400 border-b border-gray-200">
                      <div className="w-[8%] px-4 py-2"></div>
                      <div className="w-[15%] px-4 py-2">Country</div>
                      <div className="w-[15%] px-4 py-2 font-medium">
                        Channel Name
                      </div>
                      <div className="w-[15%] px-4 py-2 font-medium">
                        Category
                      </div>
                      <div className="w-[15%] px-4 py-2 font-medium">
                        All-Time Subs
                      </div>
                      <div className="w-[15%] px-4 py-2">Views - 30 Days</div>
                      <div className="w-[15%] px-4 py-2">Subs - 30 Days</div>
                      <div className="w-[15%] px-4 py-2">VPV30 Shorts</div>
                      <div className="w-[15%] px-4 py-2">VPV30 Longs</div>
                      <div className="w-[7%] px-4 py-2"></div>
                    </div>
                    {/* Table Body */}
                    {discoverData.items.map((item) => (
                      <div
                        key={item.channelId}
                        className="flex bg-white shadow-md rounded-lg mb-4 border border-gray-200 text-left py-2"
                      >
                        {/* Thumbnail */}
                        <div className="w-[8%] px-4 flex items-center">
                          <a
                            href="/"
                            className="mr-2 text-gospel-purple no-underline"
                          >
                            <img
                              src={item.channelImage}
                              alt="channel-logo"
                              className="w-12 h-12 rounded-full cursor-pointer"
                            />
                          </a>
                        </div>

                        {/* Country and Language */}
                        <div className="w-[15%]  flex items-center gap-2">
                          <Avatar
                            src={
                              <img
                                alt={item.country}
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                                  item?.country.toUpperCase() || ""
                                }.svg`}
                                className="rounded-full"
                              />
                            }
                          />
                          <div className="flex flex-col">
                            <p className="text-base text-gospel-gray-300">
                              {countryList.find((e) => e.value === item.country)
                                ?.label || "Unknown"}
                            </p>
                            <p className="text-base text-gospel-gray-200">
                              {item.language}
                            </p>
                          </div>
                        </div>

                        {/* Title */}
                        <button
                          className="w-[15%] px-4 flex items-center text-left text-gospel-purple text-base  cursor-pointer"
                          onClick={() => navigate(`/channel/${item.slug}`)}
                        >
                          {item.title}
                        </button>

                        {/* Category */}
                        <div className="w-[15%] px-4 flex items-center text-gospel-gray-300 text-base ">
                          {item.categoryName}
                        </div>

                        {/* All-Time Subs */}
                        <div className="w-[15%] px-4 flex items-center text-gospel-gray-300 text-base ">
                          {item.allTimeSubs}
                        </div>

                        {/* Views - 30 Days */}
                        <div className="w-[15%] px-4 flex items-center text-gospel-gray-300 text-base ">
                          {formatNumberWithCommas(item.thirtyDayViews)}
                        </div>

                        {/* Subs - 30 Days */}
                        <div className="w-[15%] px-4 flex items-center text-gospel-gray-300 text-base ">
                          {formatNumberWithCommas(item.thirtyDaySubs)}
                        </div>

                        {/* VPV30 Shorts */}
                        <div className="w-[15%] px-4 flex items-center text-gospel-gray-300 text-base ">
                          {formatNumberWithCommas(item.vpv30shorts)}
                        </div>

                        {/* VPV30 Longs */}
                        <div className="w-[15%] px-4 flex items-center text-gospel-gray-300 text-base ">
                          {formatNumberWithCommas(item.vpv30longs)}
                        </div>

                        {/* Action Button */}
                        <div className="w-[7%] pr-4 flex items-center">
                          {item.watchlists && item.watchlists.length > 0 && (
                            <div className="">
                              <span className="text-gospel-gray-400 text-xs">{`Added to`}</span>
                              <button
                                className="text-gospel-purple text-xs cursor-pointer"
                                onClick={() => {
                                  setWatchlistListModalOpen(true);
                                  setChannelWatchlist(item.watchlists);
                                }}
                              >
                                {` ${item.watchlists.length} ${item.watchlists.length === 1 ? "Watchlist" : "Watchlists"}`}
                              </button>
                            </div>
                          )}
                          <button
                            className="flex flex-row p-1 items-center gap-2 bg-gospel-gray-100 hover:bg-gospel-gray-300 rounded-full"
                            onClick={() => {
                              setChannelsToBeAdded(item.channelId);
                              setWatchlistModalOpen(true);
                            }}
                          >
                            <RemoveRedEyeSharp
                              className="text-white"
                              fontSize="small"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* Pagination Buttons */}
                  <div className="flex justify-center gap-4 mt-6">
                    {currentPage !== 1 && (
                      <>
                        <GospelButton
                          className="bg-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                          label="First"
                          onClick={handleFirstPage}
                        />
                        <GospelButton
                          className="bg-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                          label="Previous"
                          onClick={handlePreviousPage}
                        />
                      </>
                    )}
                    <GospelButton
                      className="bg-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                      label="Next"
                      disabled={
                        !discoverData.items || discoverData.items.length < limit
                      }
                      onClick={handleNextPage}
                    />
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 22,
                titleColor: "#353b3d",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        >
          <Modal
            title="Watchlists"
            open={watchlistListModalOpen}
            footer={null}
            closeIcon={
              <Cancel
                htmlColor="#000"
                fontSize="medium"
                onClick={() => setWatchlistListModalOpen(false)}
              />
            }
            className="text-center"
          >
            <div className="flex flex-col items-start">
              <div className="py-4 flex flex-col w-full gap-4 ">
                {channelWatchlist?.map((item) => {
                  return (
                    <a
                      className="cursor-pointer"
                      href={`/account/watchlists/list/${item.watchlistId}`}
                      key={item.watchlistId}
                    >
                      <div
                        className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                        key={item.watchlistId}
                      >
                        {item.title}
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </Modal>

          <Modal
            title="Watchlists"
            open={watchlistModalOpen}
            footer={null}
            closeIcon={
              <Cancel
                htmlColor="#000"
                fontSize="medium"
                onClick={() => setWatchlistModalOpen(false)}
              />
            }
            className="text-center"
          >
            <div className="flex flex-col items-start">
              <p className=" text-gospel-gray-300 text-base mb-6">
                Select a list below
              </p>
              <div className="flex flex-col w-full gap-4 h-52 overflow-y-scroll">
                {watchList.map((watchlistItem) => {
                  return (
                    <button
                      className="bg-gospel-white-100 hover:bg-gospel-gray-100 text-gospel-purple hover:text-black py-3 rounded-md cursor-pointer"
                      key={watchlistItem.id}
                      onClick={() => {
                        addToWatchlist(
                          watchlistItem.id,
                          channelsToBeAdded ?? ""
                        );
                        setWatchlistModalOpen(false);
                      }}
                    >
                      {watchlistItem.title}
                    </button>
                  );
                })}
              </div>
              <div className="mt-7 pt-7 w-full flex flex-col border-t border-gospel-gray-400 ">
                <GospelInput
                  type="text"
                  onChange={(e) => {
                    setNewWatchlistName(e.target.value);
                    setWatchlistNameError(""); // Clear error when input changes
                  }}
                  value={newWatchlistName}
                  placeholder="Create new list"
                  className="w-full h-10 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                />
                {watchlistNameError && (
                  <div className="text-gospel-error-red mb-2 text-sm bg-gospel-red-100 px-3 mt-2 py-2 rounded">
                    {watchlistNameError}
                  </div>
                )}
                <GospelButton
                  onClick={createNewWatchlist}
                  label={
                    loading ? (
                      <CircularProgress
                        className="text-gospel-gray-300 font-bold"
                        size={14}
                      />
                    ) : (
                      "Create"
                    )
                  }
                  disabled={!newWatchlistName.trim()}
                  className="bg-white w-28 py-2.5 mt-2 mb-8 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                />
                <span>
                  {addWatchlistloading && (
                    <CircularProgress
                      className="text-gospel-gray-300 font-bold"
                      size={14}
                    />
                  )}
                </span>
              </div>
            </div>
          </Modal>
        </ConfigProvider>
      </div>

      <Footer />
    </div>
  );
}

export default Discover;
