import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserDetails } from "../../types/account";

interface UserState {
  user: User | null;
  userDetails: UserDetails | null;
}



const initialState: UserState = {
  user: null,
  userDetails: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ user: UserState["user"] }>) {
      state.user = action.payload.user;
    },
    setUserDetails(state, action: PayloadAction<{ userDetails: UserState["userDetails"] }>) {
      state.userDetails = action.payload.userDetails;
    },
    clearUser(state) {
      state.user = null;
      state.userDetails = null;
    },
  },
});

export const { setUser, clearUser, setUserDetails } = userSlice.actions;

export default userSlice.reducer;
