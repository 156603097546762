export interface Watchlist {
  watchlistId: number;
  title: string;
}

export interface DiscoverChannelItem {
  allTimeSubs: string; // e.g., "374K"
  categoryName: string; // e.g., "People & Blogs"
  channelId: string; // e.g., "UCM1vHkCzl-YFg72jHP0CbGA"
  channelImage: string; // e.g., URL to the channel image
  country: string; // e.g., ".."
  gospelPageId: number; // e.g., 5273723
  language: string; // e.g., "English"
  rank: number; // e.g., 1
  slug: string; // e.g., "edible-youtube-shorts"
  thirtyDaySubs: string; // e.g., "-1,000"
  thirtyDayViews: string; // e.g., "0"
  title: string; // e.g., "HapeBeast NFT"
  vpv30longs: number; // e.g., 0
  vpv30shorts: number; // e.g., 0
  watchlists: Watchlist[];
}

export interface DiscoverStats {
  items: DiscoverChannelItem[];
  resultCount: number;
}

export const sortOptions = [
  {
    value: "country",
    label: "Country",
  },
  {
    value: "categoryName",
    label: "Category",
  },
  {
    value: "thirtyDayViews",
    label: "Views - 30 Days",
  },
  {
    value: "allTimeSubs",
    label: "All-Time Subs",
  },
  {
    value: "thirtyDaySubs",
    label: "Subs - 30 Days",
  },
  {
    value: "vpv30longs",
    label: "VPV30 Longs",
  },
  {
    value: "vpv30shorts",
    label: "VPV30 Shorts",
  },
];
