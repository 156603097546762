import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { Cancel, Dehaze } from "@mui/icons-material";
import type { MenuProps } from "antd";
import GospelButton from "../../common/GospelButton/GospelButton";
import { get } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";

import moment from "moment";
import {
  ConfigProvider,
  Dropdown,
  DatePicker,
  Modal,
  Tooltip,
  AutoComplete,
} from "antd";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { CircularProgress } from "@mui/material";
import { channelChartTabs } from "../Channel/ChannelUtils";
import dayjs, { Dayjs } from "dayjs";
import { determineLabelRotation, quickDateRanges } from "../../common/utils";
import { SearchChannelData } from "../../types/search";
import { debounce } from "lodash";
import { compareChartTabs, getRandomColor } from "./CompareUtils";

HighchartsMore(Highcharts);

const chartLineWidth: number = 5;

const { RangePicker } = DatePicker;

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <button onClick={() => {}}>Save/View Comparisons</button>,
  },
  {
    key: "2",
    label: <button onClick={() => {}}>Save to Dashboard</button>,
  },
];

const chartInterval: string[] = ["daily", "weekly", "monthly", "all-Time"];

const options = quickDateRanges.map((item) => ({
  value: item.value,
  label: item.label,
}));

function Compare() {
  const [loading, setLoading] = useState<boolean>(false); // Loading indicator
  const [selectedTab, setSelectedTab] = useState<number>(
    channelChartTabs[0].key
  );

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().subtract(30, "days").startOf("day"), // Subtract 29 days
    endDate: dayjs().startOf("day"), // End at today
  });
  const [selectedInterval, setSelectedInterval] = useState<string>(
    chartInterval[0]
  );
  const [loadingChart, setLoadingChart] = useState<boolean>(false);

  const [chartData, setChartData] = useState<Highcharts.Options>({
    chart: {
      type: "line",
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "line",
        name: compareChartTabs[0].label,
        // data: [9, 4, 7, 1, 6],
        color: compareChartTabs[0].dataColor,
        lineWidth: chartLineWidth,
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: "transparent", // Remove background color
      borderWidth: 0, // Remove border
      shadow: false, // Remove shadow
    },
    xAxis: {
      type: "linear",
      crosshair: {
        color: "black",
        width: 4,
      },
      categories: [],
      labels: {
        format: "{value:%b %e}",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        title: {
          text: "Views",
        },
        min: 0,
      },
    ],
    plotOptions: {
      series: {
        zIndex: 100,
        clip: false,
      },
    },
  });

  const [selectedChannels, setSelectedChannels] = useState<SearchChannelData[]>(
    []
  );

  const [fetchedChannels, setFetchedChannels] = useState<SearchChannelData[]>(
    []
  );

  const [channelOptions, setChannelOptions] = useState<
    { value: string; label: React.ReactNode }[]
  >([]);
  const [channelColors, setChannelColors] = useState<Record<string, string>>(
    {}
  );

  const fetchChannel = debounce(async (searchKey: string) => {
    if (!searchKey.trim()) {
      setChannelOptions([]);
      return;
    }

    setLoading(true);

    try {
      const result = await get<SearchChannelData[]>(
        `/youtube/channel/search?cat=channel&search_term=${searchKey.toLowerCase()}`
      );
      if (result) {
        setFetchedChannels(result);
        // Deduplicate channels based on `channelId`
        const uniqueChannels = Array.from(
          new Map(
            result.map((channel) => [channel.channelId, channel])
          ).values()
        );
        // Map to AutoComplete options with unique keys
        const fetchedOptions = uniqueChannels.map((channel) => ({
          value: channel.channelTitle, // Value for AutoComplete
          label: (
            <div className="flex flex-row items-center gap-2 my-2">
              <img
                src={channel.channelImage}
                alt={channel.channelTitle}
                className="w-12 h-12 rounded-full"
              />
              <span className="flex flex-col">
                <span className="font-montserrat text-gospel-gray-300 font-semibold">
                  {channel.channelTitle}
                </span>
                <span className="font-montserrat text-gospel-gray-300 font-normal">
                  {`${channel.allTimeSubs?.toLocaleString() ?? 0} Subscribers`}
                </span>
              </span>
            </div>
          ),
          key: channel.channelId, // Ensure the key is unique
        }));

        setChannelOptions(fetchedOptions);
      } else {
        setChannelOptions([]);
      }
    } catch (error) {
      console.error("Error fetching channels:", error);
      setChannelOptions([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleAddChannel = (channelData: any) => {
    // Find the full channel details from fetchedChannels using the key
    const selectedChannel = fetchedChannels.find(
      (channel) => channel.channelId === channelData.key
    );

    if (!selectedChannel) {
      console.warn("Selected channel not found.");
      return;
    }

    // Check if the channel exists and is not already added
    if (
      selectedChannel &&
      !selectedChannels.some(
        (item) => item.channelId === selectedChannel.channelId
      )
    ) {
      setSelectedChannels((prev) => [
        ...prev,
        {
          ...selectedChannel,
        },
      ]);
    }
    setChannelColors((prevColors) => ({
      ...prevColors,
      [selectedChannel.channelId]:
        prevColors[selectedChannel.channelId] || getRandomColor(),
    }));
  };

  const handleRemoveChannel = (channelId: string) => {
    setSelectedChannels((prev) =>
      prev.filter((channel) => channel.channelId !== channelId)
    );
    setChannelColors((prevColors) => {
      const newColors = { ...prevColors };
      delete newColors[channelId];
      return newColors;
    });
  };

  const options = quickDateRanges.map((item) => ({
    value: item.value,
    label: item.label,
  }));

  const handleTabClick = (key: number) => {
    setSelectedTab(key);
    fetchGraphData(
      key,
      selectedChannels,
      selectedInterval,
      dateRange.startDate.valueOf(),
      dateRange.endDate.valueOf()
    );
  };

  const handleIntervalChange = (interval: string) => {
    setSelectedInterval(interval);
    updateDateRange(interval);
  };

  const updateDateRange = (interval: string) => {
    const now = dayjs();
    let startDate;
    switch (interval) {
      case "daily":
        startDate = now.subtract(30, "days");
        break;
      case "weekly":
        startDate = now.subtract(90, "days");
        break;
      case "monthly":
        startDate = now.subtract(365, "days");
        break;
      case "all-Time":
        startDate = now.subtract(365, "days");
        break;
      default:
        startDate = now.subtract(30, "days");
    }
    setDateRange({ startDate, endDate: now });
  };

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (!dates || !dates[0] || !dates[1]) return;
    const startDate = dayjs(dates[0]).startOf("day");
    const endDate = dayjs(dates[1]).endOf("day");
    setSelectedValue("");
    setDateRange({ startDate, endDate });
    fetchGraphData(
      selectedTab,
      selectedChannels,
      selectedInterval,
      dateRange.startDate.valueOf(),
      dateRange.endDate.valueOf()
    );
  };

  const handleSelectChange = (value: string) => {
    const tempStartDate =
      quickDateRanges.find((e) => e.value === value)?.startDate ?? dayjs();
    const tempEndDate =
      quickDateRanges.find((e) => e.value === value)?.endDate ?? dayjs();
    setSelectedValue(value);
    setDateRange({ startDate: tempStartDate, endDate: tempEndDate });
    fetchGraphData(
      selectedTab,
      selectedChannels,
      selectedInterval,
      dateRange.startDate.valueOf(),
      dateRange.endDate.valueOf()
    );
  };
  const fetchGraphData = async (
    tabKey: number,
    channels: SearchChannelData[],
    selectedInterval: string,
    startDate: number,
    endDate: number
  ) => {
    setLoadingChart(true);
    let series: Highcharts.SeriesOptionsType[] = [];
    let uniqueDates: string[] = []; // Collect scatterplot-specific data
    const dayDifference: number = moment(endDate).diff(
      moment(startDate),
      "days"
    );

    if (selectedInterval === "all-Time") {
      compareChartTabs.forEach((tab) => {
        tab.keyName = "aggregate";
      });
    } else {
      compareChartTabs.forEach((tab) => {
        tab.keyName = "count";
      });
    }

    const tabDetails = compareChartTabs.find((tab) => tab.key === tabKey);

    if (!tabDetails) {
      console.error(`Tab with key ${tabKey} not found.`);
      return;
    }

    let dateFormat: string;
    if (selectedInterval === "daily" && selectedValue === "last12months") {
      dateFormat = "MMM YYYY"; // Example: Nov 2023
    } else if (selectedInterval === "daily" || selectedInterval === "weekly") {
      dateFormat = "MMM DD"; // Example: Nov 4
    } else if (selectedInterval === "monthly") {
      dateFormat = "MMM YYYY"; // Example: Nov 2023
    } else if (dayDifference >= 365) {
      dateFormat = "MMM YYYY";
    } else {
      dateFormat = "MMM DD";
    }

    try {
      let current = dayjs.utc(startDate);
      const end = dayjs.utc(endDate);
      const completeDates: string[] = [];
      while (current.isBefore(end) || current.isSame(end, "day")) {
        completeDates.push(current.format(dateFormat));
        current = current.add(1, "day"); // Add a week or a day
      }

      uniqueDates = Array.from(new Set(completeDates)); // Ensure no duplicates

      for (const channel of channels) {
        const graphData = await get<any[]>(
          `/youtube/stats/channel/${tabDetails.dataGraph}?channel_id=${channel.channelId}&start=${startDate}&end=${endDate}&interval=${selectedInterval}`
        );
        if (graphData) {
          const graphDates = graphData.map((e: any) => ({
            raw: dayjs.utc(e.date).format(dateFormat), // Use raw ISO format for comparison
            value: e[tabDetails.keyName],
          }));

          const filteredGraphData = uniqueDates.map((date) => {
            const match = graphDates.find((g) => g.raw === date); // Match raw ISO dates
            return match ? match.value : null; // Fill missing dates with `null`
          });

          series.push({
            type: "line",
            name: channel.channelTitle,
            data: filteredGraphData.map((value, index) => ({
              x: index, // X-axis index based on uniqueDates
              y: value !== null ? value : null, // Fill missing values with null
            })),
            color: channelColors[channel.channelId], // Use the channel's background color
            lineWidth: 4,
            marker: {
              fillColor: channelColors[channel.channelId], // Color for the bubble
              lineWidth: 0, // No border for bubbles
              fillOpacity: 1,
              radius: 4, // Keep radius the same for consistent bubble size
              symbol: "circle", // Ensure the bubble is fully round
            },
            connectNulls: true,
          });
        }
      }

      const labelRotation = determineLabelRotation(
        dayDifference,
        selectedInterval
      );


      setChartData((prev: any) => ({
        ...prev,
        series,
        yAxis: [
          {
            title: {
              text: tabDetails.label, // Dynamically set Y-axis label
            },

            visible: true,

            labels: {
              enabled: true,
              formatter: function () {
                const value = this.value as number;

                if (Math.abs(value) >= 1000000000) {
                  return value / 1000000000 + "B"; // Include sign for billions
                } else if (Math.abs(value) >= 1000000) {
                  return value / 1000000 + "M"; // Include sign for millions
                } else if (Math.abs(value) >= 1000) {
                  return value / 1000 + "K"; // Include sign for thousands
                } else {
                  return value.toString(); // Show integer values for less than 1000
                }
              },
              style: {
                fontSize: "12px",
                color: "#666666",
                fontFamily: "Montserrat, sans-serif",
                textOverflow: "none",
                whiteSpace: "nowrap",
              },
            },
          },
        ],
        tooltip: {
          useHTML: true,
          backgroundColor: "transparent",
          borderWidth: 0,
          shadow: false,
          formatter: function () {
            const xIndex = this.point.x as number;
            const dateFormat = {
              daily: "MMM DD",
              weekly: "MMM DD",
              monthly: "MMM YYYY",
              "all-Time": "MMM YYYY",
            }[selectedInterval];
            let formattedDate = uniqueDates[xIndex];
            if (selectedInterval === "weekly") {
              const startOfWeek = dayjs(uniqueDates[xIndex], dateFormat).format(
                "MMM DD"
              );
              const endOfWeek = dayjs(uniqueDates[xIndex], dateFormat)
                .add(6, "days")
                .format("MMM DD");
              formattedDate = `${startOfWeek} - ${endOfWeek}`;
            }

            let tooltipContent: any = channels
              .map((channel, index) => {
                const yValue = this.series.chart.series[index].data.find(
                  (point) => point.x === xIndex
                )?.y;

                const formattedValue = Highcharts.numberFormat(
                  yValue as number,
                  0,
                  ".",
                  ","
                );

                return `
            <span class="tooltipNumber">
              <span class="tooltipColor" style="background:${
                channelColors[channel.channelId]
              }"></span>
              <span class="tooltipKey">${channel.channelTitle}: </span>
              ${formattedValue || "N/A"}
            </span>
          `;
              })
              .join("");

            return `
      <div class="tooltip">
        <span class="tooltipDate">${formattedDate}</span>
        ${tooltipContent}
      </div>
      `;
          },
        },
        xAxis: {
          ...prev.xAxis,
          categories: uniqueDates,
          type: "datetime",
          minPadding: 0.05,
          maxPadding: 0.05,
          labels: {
            rotation: labelRotation,
            style: {
              fontSize: "12px",
              color: "#666666",
              fontFamily: "Montserrat, sans-serif",
              textOverflow: "none",
              whiteSpace: "nowrap",
            },
            y: 35,
          },
          tickWidth: 0.5,

          tickmarkPlacement: "on", // Ensure ticks are aligned with labels
        },
      }));
    } catch (error) {
      console.error("Error fetching graph data:", error);
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    if (selectedChannels.length > 0) {
      fetchGraphData(
        selectedTab,
        selectedChannels,
        selectedInterval,
        dateRange.startDate.valueOf(),
        dateRange.endDate.valueOf()
      );
    }
  }, [selectedTab, selectedInterval, dateRange, selectedChannels]);

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex items-center justify-center mt-[70px] md:mt-[70px]">
        {/* Adjust margin-top to match the header height */}
        <div className="text-center py-4 mx-8 w-full overflow-y-auto mt-5">
          {/* max-w-screen-md sets maximum width for content, overflow-y-auto enables scrolling */}
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl mb-2 text-gospel-gray">
            Compare
          </h1>

          <div className="text-center py-8 px-4  bg-white rounded-md shadow-md mt-8 ">
            <div className="flex justify-end px-4">
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Montserrat, sans-serif",
                    controlItemBgHover: "#9D63B0",
                    fontSize: 13,
                  },
                }}
              >
                <Dropdown
                  menu={{ items }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                >
                  <Dehaze />
                </Dropdown>
              </ConfigProvider>
            </div>

            {/* Input Field */}
            <div className="flex items-center  justify-center flex-col md:flex-row xl:gap-0 lg:gap-0 md:gap-0 gap-1 text-gospel-gray-300">
              <AutoComplete
                style={{ width: "25%", fontFamily: "Montserrat, sans-serif" }}
                className="custom-autocomplete"
                options={
                  loading
                    ? [
                        {
                          value: "loading",
                          label: (
                            <div className="flex items-center justify-center py-2">
                              <CircularProgress
                                className="text-gospel-gray-300 font-bold z-10"
                                size={20}
                              />
                            </div>
                          ),
                        },
                      ]
                    : channelOptions
                }
                placeholder="Search channel name, username, etc."
                onSelect={(value, option) => {
                  handleAddChannel(option);
                }}
                onSearch={(value) => {
                  fetchChannel(value);
                }}
                filterOption={false}
              />

              {/* Search Button */}
              <GospelButton
                className="text-gospel-purple h-[40px] w-[90px] mt-2 rounded-md text-sm font-medium cursor-pointer  bg-transparent border border-gospel-gray-100 ml-3"
                label="Add"
                onClick={() => {}}
              />
            </div>

            <div className="flex justify-center items-center p-4 w-full mt-8 border border-gospel-gray-100 rounded-md">
              <div className="flex flex-wrap gap-4">
                {selectedChannels.length > 0 ? (
                  selectedChannels.map((channel) => (
                    <div
                      className="flex items-center justify-between rounded-md p-2 relative cursor-pointer w-96 text-white text-left"
                      key={channel.channelId}
                      style={{ background: channelColors[channel.channelId] }}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <img
                          src={channel.channelImage}
                          alt={channel.channelTitle}
                          className="w-12 h-12 rounded-full"
                        />

                        <span className="font-medium text-sm">
                          {channel.channelTitle}
                        </span>
                      </div>

                      <Cancel
                        className=" text-white text-lg  cursor-pointer hover:text-gray-300"
                        onClick={() => handleRemoveChannel(channel.channelId)}
                      />
                    </div>
                  ))
                ) : (
                  <span className=" bg-gray-100 p-8 text-center text-gray-400 text-sm leading-[1.35] w-full max-w-md rounded-md">
                    You have no channels selected. Please add channels using the
                    search field above.
                  </span>
                )}
              </div>
            </div>

            <div className="bg-white z-50 min-h-400px py-10 px-3 shadow-md my-4">
              <div className="flex flex-wrap justify-center items-center gap-3">
                {compareChartTabs.map((tab) => (
                  <div
                    className="w-full sm:w-auto md:w-auto text-center mb-4"
                    key={tab.key}
                  >
                    <GospelButton
                      className={`h-[40px] rounded-md text-sm font-medium transition duration-500 cursor-pointer px-6 ${
                        selectedTab === tab.key
                          ? "text-white"
                          : "text-gospel-gray-400 bg-gospel-white-100 hover:text-gospel-gray-300"
                      }`}
                      style={{
                        backgroundColor:
                          selectedTab === tab.key ? "#353b3d" : "",
                      }}
                      label={tab.label}
                      onClick={() => handleTabClick(tab.key)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-wrap md:flex-nowrap">
                  {chartInterval.map((interval) => (
                    <button
                      key={interval}
                      className={`bg-white flex items-center cursor-pointer text-sm px-4 py-3 transition-colors duration-500 ease-in-out hover:text-gospel-gray-300  font-bold${
                        selectedInterval === interval
                          ? "bg-gospel-gray-300  text-gospel-gray-300"
                          : "bg-white text-gospel-gray-400"
                      }`}
                      onClick={() => {
                        handleIntervalChange(interval);
                      }}
                      onKeyUp={() => {}}
                    >
                      <span
                        className={`${
                          selectedInterval === interval
                            ? "bg-gospel-purple border-gospel-purple"
                            : "bg-white border-gospel-gray-500"
                        } rounded-full inline-block w-4 h-4 mr-1 border-2  hover:text-gray-500 transition-border duration-500 ease-in-out relative`}
                      ></span>
                      {interval.charAt(0).toUpperCase() + interval.slice(1)}
                    </button>
                  ))}
                </div>

                <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center gap-4">
                  <RangePicker
                    className="appearance-none w-64 h-10 px-3 text-sm text-gospel-purple font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                    defaultValue={[dateRange.startDate, dateRange.endDate]}
                    value={[dateRange.startDate, dateRange.endDate]}
                    onChange={handleRangeChange}
                    format="MM/DD/YYYY"
                  />

                  <div className="relative inline-block text-left">
                    <GospelSelectBox
                      options={options}
                      value={selectedValue}
                      onChange={handleSelectChange}
                      containerClass="relative max-w-[450px]"
                      className="w-60 h-10"
                      placeholder="Quick Date Ranges"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`bg-white z-50 min-h-400px py-10 px-3 shadow-md my-4 relative  ${
                  loadingChart ? "opacity-20" : ""
                }`}
              >
                {loadingChart && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                    <CircularProgress
                      className="text-gospel-gray-300 font-bold z-10"
                      size={30}
                    />
                  </div>
                )}
                <HighchartsReact highcharts={Highcharts} options={chartData} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Compare;
