import React, { useState } from "react";
import { get } from "../../utility/api";
import GospelButton from "../../common/GospelButton/GospelButton";
import GospelLabel from "../../common/GospelLabel/GospelLabel";
import GospelInput from "../../common/GospelInput/GospelInput";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import { CircularProgress } from "@mui/material";
import { ChannelSearchResponse } from "../../types/channel";
import {
  ChannelSearchVideosTable,
  timeFrameOptions,
  orderByOptions,
} from "./BrandUtils";
import { formatNumberWithCommas } from "../../utility/common";

interface ChannelSearchProps {
  brandInfo?: { id: number; name: string };
}
const parseInputValue = (value: string): number =>
  parseInt(value.replace(/,/g, ""), 10) || 0;

const ChannelSearch: React.FC<ChannelSearchProps> = ({ brandInfo }) => {
  const [minSubscribers, setMinSubscribers] = useState<string>("");
  const [maxSubscribers, setMaxSubscribers] = useState<string>("");
  const [minCollaborations, setMinCollaborations] = useState<string>("");
  const [maxCollaborations, setMaxCollaborations] = useState<string>("");
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("");
  const [selectedSort, setSelectedSort] = useState<string>("videos");
  const [selectedOrderBy, setSelectedOrderBy] = useState<string>("desc");
  const [channelSearchResults, setChannelSearchResults] =
    useState<ChannelSearchResponse>({} as ChannelSearchResponse);
  const [channelSearchLoading, setChannelSearchLoading] =
    useState<boolean>(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const limit = 25; // Number of items per page

  const onSearchChannels = async (newOffset: number = 0) => {
    setChannelSearchLoading(true);
    const formattedMinSubscribers = parseInputValue(minSubscribers);
    const formattedMaxSubscribers = parseInputValue(maxSubscribers);
    const formattedMinCollaborations = parseInputValue(minCollaborations);
    const formattedMaxCollaborations = parseInputValue(maxCollaborations);
    try {
      const response = await get<ChannelSearchResponse>(
        `/gospel/brand/data/findchannels?brand_id=${brandInfo?.id}&minsubs=${formattedMinSubscribers}&maxsubs=${formattedMaxSubscribers}&mincollabs=${formattedMinCollaborations}&maxcollabs=${formattedMaxCollaborations}&timeframe=${selectedTimeFrame}&limit=${limit}&offset=${newOffset}&sortby=${selectedSort}&orderby=${selectedOrderBy}`
      );

      setChannelSearchResults(response);
      setOffset(newOffset); // Update offset
      setCurrentPage(newOffset / limit + 1); // Update current page
    } catch (error) {
      console.error("Error fetching channel data:", error);
    } finally {
      setChannelSearchLoading(false);
    }
  };

  const handleFirstPage = () => {
    if (currentPage > 1) {
      onSearchChannels(0);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newOffset = offset - limit;
      onSearchChannels(newOffset);
    }
  };

  const handleNextPage = () => {
    if (
      channelSearchResults.items &&
      channelSearchResults.items.length === limit
    ) {
      const newOffset = offset + limit;
      onSearchChannels(newOffset);
    }
  };

  return (
    <div className="px-4 mb-12">
      <div className="flex flex-col  bg-white shadow-md py-8 items-center">
        <span className="text-xl font-semibold text-center">
          {`${brandInfo?.name} Channel Search`}
        </span>

        <div className="my-8 w-full flex flex-col justify-center items-center gap-8">
          <div className="flex flex-col md:flex-row items-center gap-3">
            <GospelLabel
              label="Total Subscribers:"
              className="text-sm text-gray-400 w-48"
            />
            <GospelInput
              type="text"
              onChange={(e) => setMinSubscribers(e.target.value)}
              value={formatNumberWithCommas(minSubscribers)}
              placeholder="Min"
              className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
            <span className="text-gray-400">-</span>
            <GospelInput
              type="text"
              onChange={(e) => setMaxSubscribers(e.target.value)}
              value={formatNumberWithCommas(maxSubscribers)}
              placeholder="Max"
              className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
          </div>

          <div className="flex flex-col md:flex-row items-center gap-3">
            <GospelLabel
              label={`Videos with ${brandInfo?.name}:`}
              className="text-sm text-gray-400 w-48"
            />
            <GospelInput
              type="text"
              onChange={(e) => setMinCollaborations(e.target.value)}
              value={formatNumberWithCommas(minCollaborations)}
              placeholder="Min"
              className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
            <span className="text-gray-400">-</span>
            <GospelInput
              type="text"
              onChange={(e) => setMaxCollaborations(e.target.value)}
              value={formatNumberWithCommas(maxCollaborations)}
              placeholder="Max"
              className="h-10 w-80 placeholder:text-gospel-gray-300 px-3 text-sm text-gospel-gray-300 font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
            />
          </div>

          <div className="flex flex-col md:flex-row items-center gap-3">
            <GospelLabel
              label="Timeframe:"
              className="text-sm text-gray-400 w-48"
            />
            <GospelSelectBox
              options={timeFrameOptions}
              value={selectedTimeFrame}
              onChange={(value) => setSelectedTimeFrame(value)}
              containerClass="relative"
              className="w-[670px] h-10"
              placeholder="Select"
            />
          </div>

          <GospelButton
            className="text-white h-[40px] w-[90px] rounded-md text-sm font-medium bg-gospel-purple hover:bg-gospel-purple-dark ml-3"
            label="Search"
            onClick={() => onSearchChannels(0)}
          />
        </div>

        {channelSearchLoading ? (
          <CircularProgress
            className="text-gospel-gray-300 font-bold z-10"
            size={30}
          />
        ) : channelSearchResults.items &&
          channelSearchResults.items.length > 0 ? (
          <div className="px-8 flex items-center flex-col w-full">
            <p>{`Total Results: ${channelSearchResults.resultCount}`}</p>
            <div className="flex flex-row w-full items-center justify-between my-4">
              <div className="flex flex-row items-center">
                <GospelLabel
                  label={"Sort By:"}
                  className="text-sm text-gray-400 w-24"
                />
                <GospelSelectBox
                  options={[
                    {
                      value: "videos",
                      label: `Videos with ${brandInfo?.name}`,
                    },
                    {
                      value: "views",
                      label: "Views",
                    },
                  ]}
                  value={selectedSort}
                  onChange={(value) => setSelectedSort(value)}
                  containerClass="relative"
                  className="w-96 h-10"
                  placeholder="Select"
                />
              </div>
              <div className="flex flex-row items-center">
                <GospelLabel
                  label={"Order By:"}
                  className="text-sm text-gray-400 w-24"
                />
                <GospelSelectBox
                  options={orderByOptions}
                  value={selectedOrderBy}
                  onChange={(value) => setSelectedOrderBy(value)}
                  containerClass="relative"
                  className="w-96 h-10"
                  placeholder="Select"
                />
              </div>
            </div>

            <ChannelSearchVideosTable
              data={channelSearchResults.items}
              brandName={brandInfo?.name}
              selectedOrderBy={selectedOrderBy}
              selectedSort={selectedSort}
            />
            <div className="flex justify-center gap-4 mt-6">
              {currentPage !== 1 && (
                <>
                  <GospelButton
                    className="bg-gospel-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                    label="First"
                    onClick={handleFirstPage}
                  />

                  <GospelButton
                    className="bg-gospel-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                    label="Previous"
                    onClick={handlePreviousPage}
                  />
                </>
              )}
              <GospelButton
                className="bg-gospel-white border border-gray-300 text-gospel-purple px-4 py-2 hover:text-gospel-gray-300 rounded text-sm"
                label="Next"
                disabled={
                  !channelSearchResults.items ||
                  channelSearchResults.items.length < limit
                }
                onClick={handleNextPage}
              />
            </div>
          </div>
        ) : (
          !channelSearchLoading &&
          channelSearchResults.resultCount === 0 && (
            <div className="text-gospel-error-red text-base bg-gospel-red-100 px-6 my-2 py-4 rounded w-4/5 text-center">
              {"No further results matching that criteria were found."}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ChannelSearch;
