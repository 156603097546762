const reportOptions = [
  { label: "Channel Report", value: "channelReport", source: "channel" },
  {
    label: "Deep Video Search Report",
    value: "deepVideoSearchReport",
    source: "video",
  },
  {
    label: "Channel Milestone Report",
    value: "channelMilestoneReport",
    source: "channelMilestone",
  },
  {
    label: "Video Milestone Report",
    value: "videoMilestoneReport",
    source: "videoMilestone",
  },
];

const criterias = [
  { label: "Lifetime Views", value: "lifetimeViews" },
  { label: "Lifetime Subscribers", value: "lifetimeSubscribers" },
  { label: "Countries", value: "countries" },
  { label: "Language", value: "language" },
  { label: "Categories", value: "categories" },
  { label: "Admin Categories", value: "adminCategories" },
  { label: "Views Over Time", value: "viewsOverTime" },
  { label: "Subscribers Over Time", value: "subscribersOverTime" },
  { label: "Views % Change", value: "viewsPercentageChange" },
  { label: "Subscribers % Change", value: "subscribersPercentageChange" },
  { label: "V30Day", value: "v30Day" },
  { label: "VPV30", value: "vpv30" },
  { label: "VPV90", value: "vpv90" },
  { label: "SHVPV30", value: "shvpv30" },
  { label: "LGVPV30", value: "lgvpv30" },
  { label: "SHVPV90", value: "shvpv90" },
  { label: "LGVPV90", value: "lgvpv90" },
  { label: "MINV30", value: "minv30" },
  { label: "MINV90", value: "minv90" },
  { label: "Shorts MINV90", value: "shortsMinv90" },
  { label: "Longs MINV90", value: "longsMinv90" },
  { label: "CLDPV30", value: "cldpv30" },
  { label: "CLDPMV30", value: "cldpmv30" },
  { label: "Uploads", value: "uploads" },
  { label: "Shorts Uploads", value: "shortsUploads" },
  { label: "Longs Uploads", value: "longsUploads" },
  { label: "Made for Kids", value: "madeForKids" },
  { label: "Shorts", value: "shorts" },
  { label: "Brands", value: "brands" },
];

const socialOptions = [
  { label: "All", value: "all" },
  { label: "Facebook", value: "facebook" },
  { label: "Twitter", value: "twitter" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Patreon", value: "patreon" },
  { label: "Twitch", value: "twitch" },
];

const adminOptions = [
  { label: "All", value: "all" },
  { label: "Anything Else", value: "15" },
  { label: "Brands", value: "5" },
  { label: "Clips (Copyright Content)", value: "13" },
  { label: "Company/Brand", value: "14" },
  { label: "Creator", value: "12" },
  { label: "Drama", value: "8" },
  { label: "Gaming", value: "7" },
  { label: "Kids & Family", value: "4" },
  { label: "Meme", value: "10" },
  { label: "Music", value: "6" },
  { label: "Musical Artist", value: "17" },
  { label: "NPC", value: "16" },
  { label: "Podcast", value: "11" },
  { label: "Science and Education", value: "9" },
  { label: "Show Room", value: "28" },
  { label: "Terminated", value: "23" },
  { label: "Topic", value: "25" },
  { label: "Vlogger", value: "1" },
];

const categoryOptions = [
  { value: "all", label: "All" },
  { value: "2", label: "Autos & Vehicles" },
  { value: "23", label: "Comedy" },
  { value: "27", label: "Education" },
  { value: "24", label: "Entertainment" },
  { value: "1", label: "Film & Animation" },
  { value: "20", label: "Gaming" },
  { value: "26", label: "Howto & Style" },
  { value: "10", label: "Music" },
  { value: "25", label: "News & Politics" },
  { value: "29", label: "Nonprofits & Activism" },
  { value: "22", label: "People & Blogs" },
  { value: "15", label: "Pets & Animals" },
  { value: "28", label: "Science & Technology" },
  { value: "17", label: "Sports" },
  { value: "19", label: "Travel & Events" },
];

const timeChangeFrameOptions = [
  { value: "last7days", label: "Last 7 Days" },
  { value: "last30days", label: "Last 30 Days" },
];

const timeFrameOptions = [
  { value: "last30days", label: "Last 30 Days" },
  { value: "last7days", label: "Last 7 Days" },
  { value: "last24hours", label: "Last 24 Hours" },
];

const uploadsTimeFrameOptions = [
  { value: "last30days", label: "Last 30 Days" },
  { value: "last90days", label: "Last 90 Days" },
];

export {
  socialOptions,
  reportOptions,
  criterias,
  adminOptions,
  categoryOptions,
  timeFrameOptions,
  timeChangeFrameOptions,
  uploadsTimeFrameOptions
};
