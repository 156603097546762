import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./route/ProtectedRoute";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import Charts from "./components/Charts/Charts";
import Discover from "./components/Discover/Discover";
import Compare from "./components/Compare/Compare";
import Search from "./components/Search/Search";
import Account from "./components/Account/Account";
import Channel from "./components/Channel/Channel";
import Brand from "./components/Brand/Brand";
import ForgotPassword from "./components/Login/ForgotPassword";
import Category from "./components/Brand/Category";
import WatchlistDetails from "./components/Account/WatchlistDetails";

const SplashScreen = () => {
  return (
    <iframe
      src="https://gospel.framer.website/"
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="Splash Screen"
    />
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          <Route path="/" element={<SplashScreen />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password" element={<ForgotPassword />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/discover" element={<Discover />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/search" element={<Search />} />
            <Route path="/account/:menu" element={<Account />} />
            <Route path="/brand/:brandId" element={<Brand />} />
            <Route path="/channel/:slug" element={<Channel />} />
            <Route
              path="/account/watchlists/list/:id"
              element={<WatchlistDetails />}
            />
            <Route path="/category/:slug" element={<Category />} />
            <Route path="*" element={<Dashboard />} />
          </Route>
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
